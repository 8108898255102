<template>
    <vs-row id="diensten" justify="center">
        <vs-col :w="wModule()" class="module box-shadow">
            <vs-row justify="center">
                <vs-col w="12"><div class="line"></div></vs-col>
                <vs-col w="12" class="space">
                    <div class="background">
                        <h1 class="title">Diensten | Inspecties</h1>
                    </div>
                </vs-col>

                <vs-col w="12">
                    <img class="landscape image" src="@/assets/images/inspecties.jpg" alt="">
                </vs-col>

                <vs-col align="left" :w="w0()">
                    <h4>
                        Vanzelfsprekend moeten technische installaties goed werken, maar ook het comfort dat deze installaties opleveren geeft een belangrijke impuls aan de 
                        werkomgeving. Met meet- en regeltechniek helpt MD Electro zijn opdrachtgevers om een comfortabel en innovatief werkklimaat te realiseren. Hierbij valt te 
                        denken aan installaties op het gebied van klimaatregeling, zonwering, verlichting en beveiliging van gebouwen. <br><br>
                        Met de meet- en regeltechniek diensten van MD Electro werken alle gebouwgebonden systemen efficiënt met elkaar samen. Meet- en regeltechniek kan daarmee een 
                        belangrijke bijdrage leveren aan de verduurzaming van gebouwen, verhoging van de bedrijfszekerheid en verlaging van de Total Cost of Ownership (TCO).<br><br>
                        MD Electro brengt comfort in ieder gebouw en is al jaren een autoriteit in meet- en regeltechniek en gebouwautomatisering. MD Electro zorgt voor optimale 
                        regelingen en integraties van alle gebouwgebonden installaties in kantoorgebouwen, maar ook in hightech omgevingen zoals ziekenhuizen, computercentra en 
                        de industrie. Goede regeltechniek verhoogt het comfort, verbetert de luchtkwaliteit en zorgt dat er bespaard kan worden op de energierekening.<br><br>
                        Dankzij de brede kennis vanuit verschillende installatiedisciplines heeft MD Electro veel ervaring met het optimaliseren van regeltechnische installaties. 
                        MD Electro verzorgt het complete regeltechnische traject, van advies en systeemengineering tot en met het beheer en de nazorg.
                    </h4>
                </vs-col>

                <vs-col w="12"><div class="line"></div></vs-col>
            </vs-row>
        </vs-col>

        <DienstenTabbie/>
    </vs-row>
</template>

<script>
import DienstenTabbie from '../components/DienstenTabbie.vue'

    export default {
        data() {
            return {

            }
        },
        components: {
            DienstenTabbie
        },

        methods: {
        wModule() {
            return this.$store.getters.smallDevice ? 12 : 10
        },
        w0() {
            return this.$store.getters.smallDevice ? 12 : 10
        },
    },
    }
</script>

<style lang="sass" scoped>
.landscape
    display: block 
    width: 80%
    height: 550px
    object-fit: cover
    margin: auto

.contact-btn
    margin: auto
    border-radius: 10px

.module
    margin-bottom: 30px


</style>